import "core-js/modules/es.array.push.js";
import { reactive, ref, onMounted } from "vue";
import { Popup } from 'vant';
import { compressImage } from '../../utils/compressImage';
import { userinfo, updateuserinfo, avatar } from "../../api/application/index";
export default {
  data() {
    return {
      info: {},
      query: {},
      radiolist: [{
        value: 1
      }, {
        value: 2
      }, {
        value: 3
      }],
      checkedValue: '',
      activeindex: 0,
      checked: 2,
      nameshow: true,
      newname: '',
      result: ['2'],
      imgurl: require('../../img/my1.png')
    };
  },
  activated() {
    this.getuserinfo();
  },
  methods: {
    //修改头像
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      this.uploadhukou(file);
    },
    // 代理资料上传
    uploadhukou(file) {
      console.log(file.content);
      $Toast.loading({
        message: '上传中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      // let formData = new FormData();
      // console.log(file);
      // formData.append("file",file);
      // console.log(formData);
      avatar({
        imgBase64: file.content
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.info.img = this.$store.state.headurl + res.imgUrl;
          $Toast.clear();
        }
      });
    },
    onOversize(file) {
      console.log(file);
      $Toast('文件大小不能超过5MB');
    },
    //跳转设置
    gosetting() {
      this.$router.push({
        path: '/my/setting'
      });
    },
    getuserinfo() {
      userinfo().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.info = res.data;
          if (this.info.img != null) {
            this.info.img = this.$store.state.headurl + this.info.img;
          }
          this.result[0] = (res.data.gender + 1).toString();
          // this.checked = res.data.gender == 1? 2:1
          console.log(this.result[0]);
        } else {
          sessionStorage.removeItem('dzmmtoken');
          this.$router.replace({
            path: '/login'
          });
        }
      });
    },
    test() {
      console.log('被选中的值为:' + this.checkedValue);
    },
    //点击隐私政策
    goyszc() {
      this.$router.push({
        path: '/home/yszc',
        query: {
          myback: true
        }
      });
    },
    updateinfo() {
      this.$router.push({
        path: '/my/userinfo'
      });
    }
  }
};